@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* html {
        font-family: "Roboto", sans-serif;
    } */
    main{
      @apply sm:pt-10 pt-5 mx-[auto] my-[0] md:mx-[auto] md:my-[0] lg:mx-[auto] lg:my-[0] sm:max-w-[300px] md:max-w-[740px] lg:max-w-[1200px];
    }
    body{
      @apply bg-white;
    }
    label {
      @apply mb-1 text-labelText font-semibold;
    }
    select{
        @apply h-10 pl-2 border-[1px] rounded-md border-greyBorder bg-white;
    }
    input{
        @apply h-10 pl-2 border-[1px] rounded-md border-greyBorder;
    }
    textarea{
      @apply h-14 pl-2 border-[1px] rounded-md border-greyBorder;
    }
    h1,h2,h3,h4,h5,h6 {
      @apply m-0;
    }
    ::-webkit-scrollbar {
      @apply w-2 h-2;
    }
    
    ::-webkit-scrollbar-track {
      @apply bg-[transparent] w-2 h-2 rounded-none;
    }

    ::-webkit-scrollbar-thumb {
    @apply bg-gradient-to-r from-lightblue to-darkblue w-2 h-2 rounded-xl;
    }

    /* input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  } */
}